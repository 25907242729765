@import "./global.css";

.wrap {
  position: absolute;
  width: 100vw;
  min-width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  /* background-image: url("../../../assets/cc7a9390d98e3aa648e97b37f287ed5c8cb5d742.png");
    background-size: cover;
    background-position: center; */
  background-color: rgba(210, 210, 210, 0.9);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1200;
}

.modal-window {
  padding: 30px;
  background-color: white;
  display: flex;
  border-radius: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: auto;
  background-color: rgba(255, 255, 255, 1);
  max-height: 90%;
}

.answers-wrap {
  width: 100%;
}

.surveys-line {
  display: flex;
  flex-wrap: wrap;
}

.survey-item {
  padding: 15px;
  background-color: var(--secondary);
  border-radius: 15px;
  margin: 10px;
  cursor: pointer;
}

.survey-item-picked {
  padding: 15px;
  background-color: var(--secondary);
  filter: brightness(1.2);
  border-radius: 15px;
  margin: 10px;
  cursor: pointer;
}

.answers {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 70vh;
  overflow-x: scroll;
  /* width: 500px; */
}
.no-answers {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  max-height: 70vh;
  overflow-x: scroll;
  width: 300px;
}

.question-answer {
  display: flex;
  flex-direction: column;
  width: 250px;
  margin: 8px;
}

.question {
  font-style: italic;
}

.answer {
  color: var(--secondary);
  margin-top: 5px;
}

.icon {
  width: 20px !important;
  height: 20px !important;
  margin-left: 10px;
}

.action-wrap {
  margin-top: 15px;
  display: flex;
  justify-content: space-evenly;
  width: 250px;
}
