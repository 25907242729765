@import "./global.css";

.data-visual-wrap {
  width: 100%;
}

.data-visual-box0 {
  background-color: lighten(#3c3d6d, 40%);
}

.data-visual-box1 {
  background-color: lighten(#3c3d6d, 35%);
}

.data-visual-box2 {
  background-color: lighten(#3c3d6d, 30%);
}

.data-visual-box3 {
  background-color: lighten(#3c3d6d, 25%);
}

.data-visual-title-period {
  margin: 0;
  padding: 10px 0;
  color: white;
  font-weight: bold;
}

.data-visual-data {
  margin: 0;
  padding: 10px 0;
  color: black;
  font-size: 2.2rem;
}

.data-visual-title {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.data-visual-units {
  color: var(--secondary);
}
