@import url("https://fonts.googleapis.com/css2?family=Raleway:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");

:root {
  --primary: #3c3d6d;
  --secondary: #f3bd4e;
}

body {
  margin: 0;
  font-family: "Raleway", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212121;
}

#drawer-dashboard > .MuiDrawer-paper {
  background-color: var(--primary);
}

#drawer-dashboard {
  width: 300px;
  height: 100vh;
}

.MuiListItemText-root .MuiTypography-body1 {
  font-family: "Raleway", sans-serif;
  font-weight: 500;
  color: white;
}

.icons.MuiSvgIcon-root {
  color: unset !important;
  height: 30px !important;
  width: 30px !important;
}

.MuiListItemIcon-root .MuiSvgIcon-root {
  color: white;
  height: 30px !important;
  width: 30px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#auth-container {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background-image: url("../assets/bg-actv2.jpg");
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

#dashboard-container {
  width: 99vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
}

.card-wrap {
  display: flex;
  max-width: 300px;
  background-color: white;
  border-radius: 8px;
  padding: 3rem;
  flex-direction: column;
  align-items: center;
  text-align: center;
}

.card-wrap img {
  max-width: 250px;
  margin-bottom: 2rem;
}

.MuiFormControl-root {
  margin: 0.5rem 0 !important;
}

.form-wrap {
  margin-bottom: 1rem;
  width: 350px;
}

.text-field {
  width: 100%;
}

.dashboard-main {
  width: calc(100% - 300px);
  /* height: 100vh; */
  flex-grow: 1;
  padding: 0rem 2rem 2rem 2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.page-form-wrap {
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
  height: 100%;
  max-width: 100vw;
}

.photo-input-wrap {
  width: 600px;
  max-width: 100%;
  height: 400px;
  margin-top: 30px;
  background-color: whitesmoke;
  position: relative;
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.photo-input-wrap-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
}

.photo-input-wrap-overlay:hover {
  background-color: rgba(105, 105, 105, 0.7);
}

.inputfile {
  width: 0.1px;
  height: 0.1px;
  opacity: 0;
  overflow: hidden;
  position: absolute;
  z-index: -1;
}

.inputfile + label {
  font-weight: 800;
  color: white;
  display: inline-block;
  cursor: pointer;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height: 80px !important;
  width: 80px !important;
}

.MuiAlert-icon .MuiSvgIcon-root {
  width:28px!important;
  height: 28px!important;
}


.MuiSvgIcon-root {
  width: unset !important;
  height: unset !important;
}

.inputfile:focus + label,
.inputfile + label:hover {
  color: var(--secondary);
}

.text-description {
  width: 600px;
  margin-top: 30px !important;
}

.button_main {
  padding: 15px 20px;
  background-color: var(--primary);
  color: white;
  font-weight: bolder;
  z-index: 5;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.button_main:hover {
  opacity:0.6;
}

.button_main.is_loading {
  cursor:disabled;
  opacity:0.6;
}

.button_main_small {
  padding: 8px 12px;
  background-color: var(--primary);
  color: white;
  font-weight: bolder;
  z-index: 5;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 0.8rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.button_secondary {
  padding: 15px 20px;
  background-color: var(--secondary);
  color: white;
  font-weight: bolder;
  z-index: 5;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.button_cancel {
  padding: 15px 20px;
  background-color: red;
  color: white;
  font-weight: bolder;
  z-index: 5;
  border-radius: 25px;
  text-align: center;
  cursor: pointer;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.button_disabled {
  padding: 15px 20px;
  background-color: #d3d3d3;
  color: white;
  font-weight: bolder;
  z-index: 5;
  border-radius: 25px;
  text-align: center;
  cursor: not-allowed;
  display: flex;
  align-items: center;
  font-size: 1.3rem;
  display: flex;
  flex-wrap: wrap;
  box-shadow: 0px 4px 8px 0px rgba(0, 0, 0, 0.2);
}

.wrap-loader {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-right: -50%;
  transform: translate(-50%, -50%);
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
}

.loader {
  border: 8px solid #f3f3f3;
  border-radius: 50%;
  border-top: 8px solid var(--primary);
  width: 60px;
  height: 60px;
  -webkit-animation: spin 2s linear infinite; /* Safari */
  animation: spin 2s linear infinite;
}

@-webkit-keyframes spin {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.dashboard-wrap {
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  justify-content: center;
}

.users-table-wrap {
  margin-top: 30px;
  margin-bottom: 30px;
  width: 100%;
  height: 80vh;
  display: flex;
  flex-direction: column;
  align-items: center;
}
/* 
@media only screen and (max-width: 600px) {
  body {
    background-color: lightblue;
  }
} */
